import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as dkiApi from '@dki/api-client';
import * as gapApi from '@gap/api-client';
import { ApplicationModuleSections } from '@libs/dash/core/entity';

import { ERROR_DIALOG_PROVIDER, ErrorDialogService, QUERY_PARAM_RESTO_UUID } from '@libs/dash/shared';
import { TranslateService } from '@libs/shared/modules/i18n';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { getRestoUuidFromMap } from '../../core/utils/functions';
import { FeatureMapService } from '../../guards/feature-map/feature-map.service';
import { getCurrentLanguageParam, getSetNewPasswordTokenQueryParam } from '../router/selectors';
import { RootStoreState } from '../state';
import * as AuthStoreActions from './actions';

@Injectable()
export class AuthEffects {
	login = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.login),
			mergeMap((action) => {
				return this._authApiClient.loginV1AuthLoginPost(action.userName, action.password).pipe(
					map((accessToken: dkiApi.OAuth2AccessToken) => {
						const restoUuid = getRestoUuidFromMap(this._route.snapshot.queryParamMap);
						if (restoUuid) {
							sessionStorage.setItem(QUERY_PARAM_RESTO_UUID, restoUuid);
						} else {
							sessionStorage.removeItem(restoUuid);
						}

						sessionStorage.setItem('dki_session_id', accessToken.access_token);
						return AuthStoreActions.getCurrentUserProfile({ accessToken });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(AuthStoreActions.loginFail({ error }));
					})
				);
			})
		)
	);

	loginFail = createEffect(
		() =>
			this._actions.pipe(
				ofType(AuthStoreActions.loginFail),
				withLatestFrom(this._store.pipe(select(getCurrentLanguageParam))),
				tap(([action, currentLanguageParamm]) => {
					this._router.navigateByUrl(`/account/login`);
					this._errorDialogService.popUp('Login Failed', action.error.message);
				})
			),
		{ dispatch: false }
	);

	requestPasswordRenewal = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.requestPasswordRenewal),
			withLatestFrom(this._store.pipe(select(getCurrentLanguageParam))),
			mergeMap(([action, currentLanguageParamm]) => {
				return this._authApiClient.sendPasswordResetTokenV1AuthSendPasswordResetTokenPost({ email: action.email }).pipe(
					map(() => {
						this._router.navigateByUrl(`/account/password-renewal-sent`);
						return AuthStoreActions.requestPasswordRenewalSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(AuthStoreActions.requestPasswordRenewalFail({ error }));
					})
				);
			})
		)
	);

	requestPasswordRenewalFail = createEffect(
		() =>
			this._actions.pipe(
				ofType(AuthStoreActions.passwordSetupFail),
				withLatestFrom(this._store.pipe(select(getCurrentLanguageParam))),
				tap(([action, currentLanguageParamm]) => {
					this._router.navigateByUrl(`/account/login`);
					this._errorDialogService.popUp('Password Renewal Request Failed', action.error.message);
				})
			),
		{ dispatch: false }
	);

	passwordSetup = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.passwordSetup),
			withLatestFrom(this._store.pipe(select(getCurrentLanguageParam)), this._store.pipe(select(getSetNewPasswordTokenQueryParam))),
			mergeMap(([action, currentLanguageParamm, setNewPasswordToken]) => {
				const resetPasswordInfo: gapApi.ResetPasswordInfo = {
					token: setNewPasswordToken,
					new_password: action.password,
				};

				return this._authApiClient.resetPasswordV1AuthResetPasswordPost(resetPasswordInfo).pipe(
					map(() => {
						this._router.navigateByUrl(`/account/setup-completed`);
						return AuthStoreActions.passwordSetupSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(AuthStoreActions.passwordSetupFail({ error }));
					})
				);
			})
		)
	);

	passwordSetupFail = createEffect(
		() =>
			this._actions.pipe(
				ofType(AuthStoreActions.passwordSetupFail),
				withLatestFrom(this._store.pipe(select(getCurrentLanguageParam))),
				tap(([action, currentLanguageParamm]) => {
					this._router.navigateByUrl(`/account/login`);
					this._errorDialogService.popUp('Password Setup Failed', action.error.message);
				})
			),
		{ dispatch: false }
	);

	refreshSessionFromCookie = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.refreshSessionFromCookie),
			mergeMap((action) =>
				this._myApiClient.myProfileV1MyProfileGet().pipe(
					map(
						(userInfo: gapApi.UserInfo) => {
							if (this.isMatchingUser(userInfo)) {
								console.warn(`[APP]: ${userInfo.email} is the same as API user and is missing resto_uuid. Access restricted.`);
								return AuthStoreActions.logout();
							}

							return AuthStoreActions.refreshSessionFromCookieSuccess({ userInfo, url: action.url });
						},
						catchError((error: HttpErrorResponse) => of(AuthStoreActions.refreshSessionFromCookieFail({ error })))
					)
				)
			)
		)
	);

	refreshSessionFromCookieSuccess = createEffect(
		() =>
			this._actions.pipe(
				ofType(AuthStoreActions.refreshSessionFromCookieSuccess),
				tap((action) => {
					this._router.navigateByUrl(action.url);
				})
			),
		{ dispatch: false }
	);

	logout = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.logout),
			mergeMap(() =>
				this._authApiClient.logoutV1AuthLogoutPost().pipe(
					map(
						() => {
							sessionStorage.removeItem('dki_session_id');
							sessionStorage.removeItem(QUERY_PARAM_RESTO_UUID);
							localStorage.clear();
							return AuthStoreActions.logoutSuccess();
						},
						catchError((error: HttpErrorResponse) => of(AuthStoreActions.logoutFail({ error })))
					)
				)
			)
		)
	);

	getCurrentUserProfile = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.getCurrentUserProfile),
			withLatestFrom(this._store.pipe(select(getCurrentLanguageParam)), this._translateService.selectTranslation('login')),
			// var action is not used, but must be declared within mergeMap to be abble to process success effect
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			mergeMap(([action, currentLanguageParamm, transaltedTexts]) =>
				this._myApiClient.myProfileV1MyProfileGet().pipe(
					map(
						(userInfo: gapApi.UserInfo) => {
							if (this.isMatchingUser(userInfo)) {
								console.warn(`[APP]: ${userInfo.email} is the same as API user and is missing resto_uuid. Access restricted.`);
								return AuthStoreActions.logout();
							}
							const homepageUrl = this._featureService.featureMap.homepage;
							if (!Object.values(ApplicationModuleSections).includes(homepageUrl)) {
								this._router.navigateByUrl(`${ApplicationModuleSections.GLOBAL_OVERVIEW}`);
							} else {
								this._router.navigateByUrl(`${homepageUrl}`);
							}
							this._snackBar.open(`${transaltedTexts.snackbars.success}`, `${userInfo.first_name || ''} ${userInfo.last_name || ''}`, {
								duration: 2000,
							});

							return AuthStoreActions.getCurrentUserProfileSuccess({
								userInfo,
							});
						},
						catchError((error: HttpErrorResponse) => of(AuthStoreActions.getCurrentUserProfileFail({ error })))
					)
				)
			)
		)
	);

	getCurrentUserProfileSourced = createEffect(() =>
		this._actions.pipe(
			ofType(AuthStoreActions.getCurrentUserProfileSourced),
			mergeMap((action) =>
				this._myApiClient.myProfileV1MyProfileGet().pipe(
					map(
						(userInfo: gapApi.UserInfo) => {
							if (this.isMatchingUser(userInfo)) {
								console.warn(`[APP]: ${userInfo.email} is the same as API user and is missing resto_uuid. Access restricted.`);
								return AuthStoreActions.logout();
							}

							return AuthStoreActions.getCurrentUserProfileSuccess({
								userInfo,
							});
						},
						catchError((error: HttpErrorResponse) => of(AuthStoreActions.getCurrentUserProfileFail({ error })))
					)
				)
			)
		)
	);

	constructor(
		private _store: Store<RootStoreState>,
		private _router: Router,
		private _route: ActivatedRoute,
		private _actions: Actions<Action>,
		private _authApiClient: gapApi.AuthApiClient,
		private _myApiClient: gapApi.MyApiClient,
		private _snackBar: MatSnackBar,
		private _translateService: TranslateService,
		private _featureService: FeatureMapService,
		@Inject(ERROR_DIALOG_PROVIDER) private _errorDialogService: ErrorDialogService
	) {}

	isMatchingUser(userInfo: gapApi.UserInfo): boolean {
		return userInfo.email === this._featureService.featureMap.managerUser && !sessionStorage.getItem(QUERY_PARAM_RESTO_UUID);
	}
}
