import { BrandName } from '../enums';

export function isBkFR(brandName: BrandName): boolean {
	return brandName === BrandName.BurgerKing_France;
}

export function isBkRE(brandName: BrandName): boolean {
	return brandName === BrandName.BurgerKing_Reunion;
}

export function isBk(brandName: BrandName): boolean {
	return isBkFR(brandName) || isBkRE(brandName);
}

export function isCMRE(brandName: BrandName): boolean {
	return brandName === BrandName.CapMerchant_Reunion;
}

export function isHDFR(brandName: BrandName): boolean {
	return brandName === BrandName.HollysDiner;
}

export function isCOFR(brandName: BrandName): boolean {
	return brandName === BrandName.Cora_France;
}

export function isQuick(brandName: BrandName): boolean {
	return brandName === BrandName.Quick;
}

export function isPMFR(brandName: BrandName): boolean {
	return brandName === BrandName.PokeMama_France;
}

export function isBCFR(brandName: BrandName): boolean {
	return brandName === BrandName.BChef_France;
}

export function isBrandBasedOnQuickInitialFeatures(brandName: BrandName): boolean {
	const brands = [
		BrandName.Quick,
		BrandName.BChef_France,
		BrandName.CapMerchant_Reunion,
	];
	return brands.includes(brandName);
}
