
/***************************************************************
 * This file contains the enumeration used in common structures
 ***************************************************************/
export enum BKDeliveryModeEnum {
	NONE = -1,
	/**
	 * 0 aka EatIn
	 */
	DELIVERY_LOCAL = 0,
	/**
	 * 1 aka TakeAway
	 */
	DELIVERY_TAKE_OVER = 1,

	// These are all considered TakeAway
	DELIVERY_BK = 2,
	DELIVEROO = 3,
	UBER_EAT = 4,
	JUST_EAT = 5,
	ZOT = 6,
	LYVEAT = 7,
	PLEASE = 8,
}

/**
 * According to Alexis B, the enum masks are used mostly in BO Global for configuration.
 * Also for some "BkSuggestion"
 */
export enum BKDeliveryModeMask {
	NONE = 0,
	DELIVERY_LOCAL = 2 ** BKDeliveryModeEnum.DELIVERY_LOCAL, // outputs 1
	DELIVERY_TAKE_OVER = 2 ** BKDeliveryModeEnum.DELIVERY_TAKE_OVER, // outputs 2
}

export enum BKItemSelectionPatternSourceEnum {
	NONE = 0,
	PRODUCT = 1,
	MENU = 2,
	INGREDIENT = 3,
	KIOSK_NAVIGATION_MENU = 4,
	POS_NAVIGATION_MENU = 5,
	RESTAURANT = 6,
	DISCOUNT = 7,
	ANNOTATION = 8,
	GAME = 9,
	COUPON = 10,
}

export enum BKItemSelectionPatternTarget {
	NONE = 'none',
	PRODUCT = 'product',
	MENU = 'menu',
	INGREDIENT = 'ingredient',
	KIOSK_NAVIGATION_MENU = 'kiosk_navigation_menu',
	POS_NAVIGATION_MENU = 'pos_navigation_menu',
	RESTAURANT = 'restaurant',
	DISCOUNT = 'discount',
	ANNOTATION = 'annotation',
	GAME = 'game',
	COUPON = 'coupon',
}

export enum BKSelectionPatternFamilyPropagation {
	PARENTS_ONLY ='parents_only',
	FAMILY_AND_PARENTS = 'family_and_parents',
	FAMILY_ONLY = 'family_only',
	FAMILY_AND_CHILDREN = 'family_and_children',
	CHILDREN_ONLY = 'children_only',
}

export enum SalesChannelAndDeliveryOption {
	DELIVEROO = 'DELIVEROO',
	DELIVERY_BK = 'DELIVERY_BK',
	EAT_IN = 'EAT_IN',
	JUST_EAT = 'JUST_EAT',
	TAKE_AWAY = 'TAKE_AWAY',
	UBER_EATS = 'UBER_EATS',
	ZOT = 'ZOT',
	PLEASE = 'PLEASE',
	LYVEAT = 'LYVEAT',
}

export enum BKMediaTypeEnum {
	NONE = 0,
	IMAGE = 1,
	VIDEO = 2,
	HTML5 = 3,
}

/**
 * The enumaration contains the different types of pick-up type
 * of web orders for example
 */
export enum BKPickUpTypeEnum {
	NONE = 'NONE',
	/**
	 * Click-and-collect sends PICK_UP, but it actually means TAKE_AWAY...
	 */
	TAKE_AWAY = 'PICK_UP',
	PARKING = 'PARKING',
	DRIVE = 'DRIVE',
	ON_SITE = 'ON_SITE',
	TABLE_SERVICE = 'TABLE_SERVICE',
	CLICK_AND_COLLECT = 'CLICKANDCOLLECT',
}

/*******************************************************************************
 * Ingredients
 ******************************************************************************/

export enum BKIngredientAmountTypeEnum {
	SINGLE_ONE = 0,
	NO_LIGHT_NORMAL_HEAVY = 1, //deprecated
}

export enum BKIngredientAmountEnum {
	ON = 0,
	LIGHT = 1,
	NORMAL = 2,
	HEAVY = 3,
}

/*******************************************************************************
 * Suggestion display type
 ******************************************************************************/

export enum BKSuggestionDisplayType {
	/**
	 * Standard dialog
	 */
	DIALOG = 0,
	/**
	 * Deprecated
	 */
	BUBBLE = 1,
	/**
	 * Deprecated
	 */
	NAV_MENU_LIKE = 2,
	/**
	 * Suggestion to upgrade product in menu. Suggestion needs to be applicable to menu.
	 * Then if one of the products in in current step and it is not already selected suggestion will be displayed
	 */
	SUGGESTION_IN_TUNNEL = 3,
	/**
	 * Suggest upgrade to large menu
	 */
	SUGGESTION_LARGE_MENU_SIZE = 4,
	/**
	 * Displayed at the end of menu tunnel. Selected product will be pushed to menu
	 */
	PUSH_EXTRA_MENU_PRODUCT = 5,
	PUSH_EXTRA_MENU_PRODUCT_THIRD_PARTY = 6,
	/**
	 * Quick speciality. Suggestion needs to be aplicable to specific product.
	 * If this product is in current menu step suggestion will be displayed even if the suggested product is not part of this menu step.
	 */
	UPGRADE_PRODUCT_IN_MENU = 7,
}

export enum SuggestionBackgroundPositionVertical {
	NONE = 0,
	TOP = 1,
	CENTER = 2,
	BOTTOM = 3
}

export enum SuggestionBackgroundPositionHorizontal {
	NONE = 0,
	LEFT = 1,
	CENTER = 2,
	RIGHT = 3
}

/*******************************************************************************
 * Option type enum
 ******************************************************************************/

export enum BKOptionTypeEnum {
	NONE = 0,
	FREE_PRODUCT_OPTIONAL = 1,
	FREE_PRODUCT = 2,
	PAID_PRODUCT = 3,
	INGREDIENT_PICKUP = 4,
	INGREDIENT_REMOVE = 5,
	INGREDIENT_RESTORE = 6,
	INGREDIENT_FULL_PICKUP = 7,
	INGREDIENT_EXTRA_PICKUP = 8,
}

export enum BKOptionTypeEnumV2 {
	NONE = 'none',
	FREE_PRODUCT_OPTIONAL = 'free_product_optional',
	FREE_PRODUCT = 'free_product',
	PAID_PRODUCT = 'paid_product',
	INGREDIENT_FULL_PICKUP = 'ingredient_full_pickup',
	INGREDIENT_EXTRA_PICKUP = 'ingredient_extra_pickup',
}

export enum BKOptionVersion {
	OPTION_LEGACY = 'OPTION_LEGACY',
	OPTION_V2 = 'OPTION_V2',
}

/*******************************************************************************
 * Discount type enum
 ******************************************************************************/

export enum BKDiscountTypeEnum {
	NONE = 0,
	MENU_DISCOUNT = 1,
	PRODUCT_DISCOUNT = 2,
	ORDER_DISCOUNT = 3,
	MENU_SINGLE_PRICE = 4,
	PRODUCT_SINGLE_PRICE = 5,
	PRODUCT_AND_EXTRA_DISCOUNT = 6,
	MENU_AND_EXTRA_DISCOUNT = 7,
	MENU_EXCLUSIVE = 8,
	PRODUCT_EXCLUSIVE = 9,
	/**
	 * Global discount utilized on refund. Applied globally on everything including
	 * items that are flagged with "_excludeFromGlobalDiscount".
	 **/
	REFUND_DISCOUNT = 100,
}

/*******************************************************************************
 * Enumeration containing the flags for discount
 ******************************************************************************/

export enum BKDiscountFlagMask {
	NONE = 0,
	ITEM_CANNOT_BE_LEFT_WITHOUT_DISCOUNT = 2 ** 0,
	MENU_WITH_MORE_EXPENSIVE_FIRST_STEP = 2 ** 1,
}

/*******************************************************************************
 * Type of an annotation
 ******************************************************************************/

export enum BKAnnotationTypeEnum {
	TEXT = 0,
	SYSTEM_SAME_CAR = 1,
	SYSTEM_EMPTY_CAR = 2,
}

/*******************************************************************************
 * Dispatch strategy for the ORB
 ******************************************************************************/
	// This is Legacy mode LoadBalancing
export enum BKORBDispatchStrategy {
	ALL = 0,
	ODD = 1,
	EVEN = 2,
	SPECIFIC = 3,
	ORB_ODD = 4,
	ORB_EVEN = 5,
	NONE = 6,

	DYNAMIC_LOAD_MASTER = 7,
	DYNAMIC_LOAD_SLAVE = 8,

	MODULO3_0 = 9,
	MODULO3_1 = 10,
	MODULO3_2 = 11,

	MODULO4_0 = 12,
	MODULO4_1 = 13,
	MODULO4_2 = 14,
	MODULO4_3 = 15,

	/**
	 * Dynamic load with ODD orb filtering
	 */
	DYNAMIC_LOAD_MASTER_ORB_ODD = 16,
	/**
	 * Dynamic load with ODD orb filtering
	 */
	DYNAMIC_LOAD_MASTER_ORB_EVEN = 17,
}

/*******************************************************************************
 * Dispatch strategy for the KS
 ******************************************************************************/

export enum BKKDSDispatchStrategy {
	ALL = 0,
	ODD = 1,
	EVEN = 2,

	/**
	 * ODD ORB for KIOSK source only
	 */
	ODD_KIOSK_SOURCE = 3,
	/**
	 * EVEN ORB for KIOSK source only
	 */
	EVEN_KIOSK_SOURCE = 4,
}

/*******************************************************************************
 * Enumeration of game algo
 ******************************************************************************/

export enum BKGameAlgoEnum {
	NONE = 0,
	RANDOM_PRODUCT = 1,
	GIFT_WITH_LARGE_MENU = 2,
	RANDOM_DISCOUNT = 3,
	ERROR_IN_FAVOR = 4,
}

/**
 * Order event argument keys used for order events - at least in Kiosk v2.0
 */
export enum BKOrderEventArgumentKeys {
	KIOSK_NAVIGATION = 'kn',
	PRODUCT = 'p',
	MENU = 'm',
	ADD = 'add',
	LINES = 'lines',
	REMOVE = 'remove',
	STEP_INDEX = 'stepIdx',
	CAMPAIGNS = 'campaigns',
	DELIVERY = 'delivery',
	LANGUAGE = 'lang',
	VERSION = 'version',
	PORTRAIT = 'portrait',
	IDS = 'ids',
	EASEL_NUM = 'easelNum',
	LOCATION_SPACE = 'locationSpace',
	FIDELITY_LOGIN_FROM_MAIN_SCREEN = 'fidelityLoginFromMainScreen'
}

/*******************************************************************************
 * This enumeration is used in order to describe the type of event.
 *
 * It is rather collection of Metadata associated with an Order than actual Events.
 ******************************************************************************/
export enum BKOrderEventType {
	NONE = 0,
	NAV_DELIVERY_MODE = 1,
	NAV_HOME_MEDIA = 2,
	NAV_CATEGORY = 3,
	NAV_SUB_MENU = 4,
	NAV_HOME_BTN = 5,
	NAV_SCROLL_ARROW = 6,
	NAV_DOWN_SCROLL = 7,
	NAV_BURGER_FILTER = 8,
	MENU_TUNNEL_START = 100,
	MENU_VALID_STEP = 101,
	MENU_VALID_TUNNEL = 102,
	MENU_CANCEL_TUNNEL = 103,
	PERSO_FROM_POPUP = 200,
	PERSO_FROM_MENU = 201,
	PERSO_FROM_PRODUCT_PUSH = 202,
	BASKET_ITEM_ADD = 300,
	BASKET_ITEM_REMOVE = 301,
	BASKET_ITEM_QTY = 302,
	BASKET_MENU_STEP_EDIT = 303,
	BURGER_FINDER_OPEN = 400,
	BURGER_FINDER_CHOICE = 401,
	BURGER_FINDER_ORDER = 402,
	ALLERGEN_ADD = 500,
	POPUP_IN_MENU_SUGGESTION_ORDER = 600,
	POPUP_INFO_ORDER = 601,
	POPUP_INFO_OPEN = 602,
	POPUP_INFO_NAV = 603,
	POPUP_IN_MENU_SUGGESTION_DISMISSED = 604,
	SUGGESTION_END_MENU_BTN = 700,
	SUGGESTION_ORDER_ITEM = 701,
	SUGGESTION_DISMISSED = 702,
	PAY_POPUP_CANCEL = 800,
	CRM_POPUP_OPEN = 900,
	CRM_POPUP_START_COLLECT_PHONE = 901,
	CRM_INVALID_PHONE = 902,
	CRM_VALID_PHONE = 903,
	CRM_PHONE_REQUEST_ERROR = 904,
	CRM_PRINT_TICKET = 905,
	CRM_PRINT_ERROR = 906,
	CRM_CLICK_ON_CLOSE = 907,
	CRM_CLICK_ON_NEW_ORDER = 908,
	CRM_CLICK_ON_DECLINE = 909,
	CRM_PHONE_NUMBER = 910,
	INFO_ORDER_SOURCE = 1000,
	INFO_ORDER_TABLET = 1001,
	INFO_ORDER_KIOSK = 1002,
	KINGDOM_DISCOUNT_IN_ORDER = 1100,
	KINGDOM_CLIENT_LOGIN = 1101,
	BURGER_MYSTERE_LOGIN = 1200,
	BURGER_MYSTERE_BURN = 1201,
	DEMOCRATIC_BURGER_LOGIN = 1210,
	DEMOCRATIC_BURGER_BURN = 1211,
	LOCAL_MAILINGS_DISCOUNT_IN_ORDER = 1220,
	LOCAL_MAILINGS_LOGIN = 1221,
	LOCAL_MAILINGS_BURN = 1222,
	FIDELITY_POINTS = 1223,
	TGF_COUPON_LOGIN = 1224,
	TGF_COUPON_BURN = 1225,
	/** The long alien ID assigned by delivery service */
	WEBORDER_ALIEN_ORDER_ID = 2000,
	/**
	 * This seems to indicate Delivery Channel, aka "delivery company" - UberEats, Deliveroo etc
	 */
	WEBORDER_SANDBOX_NAME = 2001,
	/**
	 * Due date means that customer ordered the order to be ready at certain time in future.
	 */
	WEBORDER_DELIVERY_DUE_DATE = 2002,
	WEBORDER_SYNC_URL = 2003,
	WEBORDER_STATUS_URL = 2004,
	/*** Alien means coming from other system, like Ecocea or UberEats. This is the shor ID which is shown to the user of the given app, e.g. BK app (4 characters) and should be displayed on ORBp and ORB screens */
	WEBORDER_ALIEN_ORDER_ID_DISPLAY = 2005,
	WEBORDER_CLIENT_ID = 2006,
	/** TABLE_NUMBER will now be included in in 4001 TABLE_ALLOCATION event */
		// WEBORDER_META_TABLE_NUMBER = 2007,
	WEBORDER_PICKUP_TYPE_PARKING_DETAILS = 2008, // has additional details about Parking pick-up
	WEBORDER_META_PICKUP_TYPE = 2009,
	WEBORDER_PRODUCED_FROM_APP = 2010, // order sent to prod from eccocea app
	WEBORDER_PRODUCED_FROM_DRIVE = 2011, // order sent to prod from driveco
	WEBORDER_PRODUCED_FROM_DRIVE_WITH_MODIFICATION = 2012, // order sent to prod from driveco, with order modification
	/**
	 * Order from kiosk is retrieved on pos by code scan or selection from the list
	 */
	RETRIEVE_ON_POS = 2013,
	WHOPPER_SCRATCHER_COUPON = 2014,
	RECEIVED_ON_DRIVEPAY = 2015,

	/**
	 * Contains all the timeBasedPredictions for digital payment = card token, app name, payment scheme
	 */
	DIGITAL_PAYMENT = 2016,
	DRINK_FOUNTAIN_CODES = 2017,
	PAGER_NUMBER = 2018,
	/**
	 * For BKRE. Info about employee and manager
	 */
	EMPLOYEE_MEAL = 2019,
	CSI_COMPLEMENTS = 2020,
	/**
	 * BKFR employee meal is splitted to two orders. This value is orb number of the first order
	 */
	EMPLOYEE_MEAL_ORB = 2021,
	CALL_OF_DUTY_CODES = 2022,
	ORDER_ITEM_REMOVED = 2023,
	ORDER_CANCELED = 2024,
	ORDER_PRICE_UPDATE_FAIL = 2025,

	/**
	 * Order has been pushed to production from POS, but has been already paid some time before.
	 */
	PAID_DATE_PATCHED = 2050,

	/**
	 * Total returned by last putCommande request, arg contain the price as string (parseFloat it)
	 */
	CSI_DECLARATION_TTC = 3001,
	/**
	 * Table allocation (initially for COVID19, now replaces TABLE_NUMBER (2007) and TERRACE (2014))
	 */
	TABLE_ALLOCATION = 4001,

	/**
	 * Old orderUUID, that was changed in DRIVECO (apps\bk-cash\src\api\bkgetorderfromdriveco.php). Needed for callback hooks.
	 */
	OLD_ORDER_UUID = 5001,

	OLD_ORB_NUMBER = 5002,

	CUSTOM_USER_NAME = 6001,
	CUSTOM_FIDELITY_LOG_IN_FROM_SCREEN = 6002,

	/** Customer detail to print on ticket. */
	CUSTOMER_DETAIL = 6003,
	/** Delivery detail to print on ticket. */
	DELIVERY_DETAIL = 6004,
	/** Order note to print on ticket */
	ORDER_NOTE = 6005,

	VIRTUAL_TICKET = 7000,
}

/*******************************************************************************
 * Enumeration containing the currently used metadata variables in weborders
 ******************************************************************************/

export enum BKOrderMetaType {
	/** Contains ID of suggestion */
	FROM_SUGGESTION = 'from_suggestion',
	/** Contains ID of campaign */
	IN_CAMPAIGN = 'in_campaign',
	/** Contains alien ORB number (e.g. the number you see in BK app) */
	ORB_NUMBER = 'orbNumber',
	/** Contains pick up type - values BKPickUpTypeEnum */
	PICK_UP_TYPE = 'pickUpType',
	/** Contains table number in case of TABLE_SERVICE pick up type (mandatory - or easelNUmber) */
	TABLE_NUMBER = 'tableNumber',
	/** Contains easel number in case of TABLE_SERVICE pick up type (mandatory - or tableNumber) */
	EASEL_NUMBER = 'easelNumber',
	/** Contains "bool" value if the customer is sitting on the terracein case of TABLE_SERVICE pick up type (optional, false by default) */
	TERRACE = 'terrace',
	/** Contains license plate of car in case of PARKING pick up type (mandatory) */
	LICENSE_PLATE_NUMBER = 'licensePlateNumber',
	/** Contains car model in case of PARKING pick up type (optional) */
	CAR_MAKE_AND_MODEL = 'carMakeAndModel',
	/** Contains license plate of car in case of PARKING pick up type (optional) */
	CAR_COLOR = 'carColor',
	/** Contains parking slot number in case of PARKING pick up type (optional) */
	PARKING_SLOT = 'parkingSlot',

	/** Data necessary to implement digital payments in Ecocea app (e.g. PayPal, ApplePay, etc.) */
	DP_CARD_TOKEN = 'nep_CardToken',
	DP_APPLICATION_NAME = 'nep_ApplicationName',
	DP_PAYMENT_SCHEME = 'nep_PaymentScheme',
}

/*******************************************************************************
 * Enumeration containing the flags for product
 ******************************************************************************/

export enum BKProductFlagMask {
	PRODUCT_FLAG_NONE = 0,
	PRODUCT_FLAG_THAWED = 2 ** 0,
}

/*******************************************************************************
 * Enumeration containing the flags for the suggestion display methods
 ******************************************************************************/

export enum BKSuggestionDisplayMethodsMask {
	SUGGESTION_METHOD_NONE = 0,
	SUGGESTION_METHOD_DISPLAY_ONLY_ONCE = 2 ** 0,
	SUGGESTION_METHOD_DISPLAY_ONLY_IF_NO_ITEM_ORDERED = 2 ** 1,
	SUGGESTION_METHOD_DISPLAY_BEFORE_PAY = 2 ** 2,
	SUGGESTION_METHOD_DISPLAY_ONLY_IF_NO_ITEM_FROM_APPLICATION_ORDERED = 2 ** 3,
	SUGGESTION_METHOD_DISPLAY_ON_PRODUCT_IN_MENU = 2 ** 4,
	SUGGESTION_METHOD_DISPLAY_AFTER_EAT_IN_SELECTED = 2 ** 5,
	SUGGESTION_METHOD_DISPLAY_AFTER_TAKE_OUT_SELECTED = 2 ** 6,
}

/*******************************************************************************
 * Enumeration containing the flags for ingredient filter location
 ******************************************************************************/

export enum BKIngredientFilterLocationMask {
	LOCATION_NONE = 0,
	LOCATION_NAVIGATION_SCREEN_FILTER = 2 ** 0,
	LOCATION_BURGER_FINDER_SLIDE = 2 ** 1,
}

/*******************************************************************************
 * Enumeration containing the picto reference for a product family
 ******************************************************************************/

export enum BKProductFamilyPictoEnum {
	NONE = 0,
	BURGER = 1,
	SNACK = 2,
	COLD_DRINK = 3,
	HOT_DRINK = 4,
	SALAD = 5,
	TOY = 6,
	SWEET = 7,
	SAUCE = 8,
	BREAKFAST = 9,
}

/*******************************************************************************
 * Enumeration containing the stepType for a menu step
 ******************************************************************************/

export enum BKMenuStepTypeEnum {
	NONE = 0,
	BURGER = 1,
	SIDE = 2,
	DRINK = 3,
	SWEET = 4,
	TOY = 5,
	GIFT = 6,
	OTHER = 7,
}

/**
 * Just ot reflect BKMenuStepTypeEnum, so its possitble to generate schemas automatically
 */
export enum MenuStep {
	NONE = 0,
	BURGER = 1,
	SIDE = 2,
	DRINK = 3,
	SWEET = 4,
	TOY = 5,
	GIFT = 6,
	OTHER = 7,
}

export enum MenuBaseSizeEnum {
	MEDIUM = 0,
	SMALL = 1,
}

export enum BKMenuSizeEnum {
	NONE = 0,
	MEDIUM = 1,
	LARGE = 2,
}

/**
 * Just ot reflect BKMenuSizeEnum, so its possitble to generate schemas automatically
 */
export enum MenuSize {
	NONE = 0,
	MEDIUM = 1,
	LARGE = 2,
}

export enum BKMenuSizeMask {
	NONE = 0,
	MEDIUM = 2 ** BKMenuSizeEnum.MEDIUM,
	LARGE = 2 ** BKMenuSizeEnum.LARGE,
}

/*******************************************************************************
 * Enumeration containing the priority of a campaign
 ******************************************************************************/

export enum BKCampaignNavigationPriority {
	NORMAL = 0,
	HIGH = 1,
	VERY_HIGH = 2,
}

export enum BKCampaignDayOfWeekMaskEnum {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 4,
	THURSDAY = 8,
	FRIDAY = 16,
	SATURDAY = 32,
	SUNDAY = 64,
}

export enum BKCampaignFidelityFilterTypeEnum {
	NONE = 0,
	IDENTIFIED = 1,
	IDENTIFIED_ABOVE_LIMIT = 2,
	IDENTIFIED_BETWEEN_LIMITS = 3,

	NOT_IDENTIFIED = 100,
}

export enum BKMediaFidelityFilterTypeEnum {
	NONE = 0,
	IDENTIFIED = 1,
	IDENTIFIED_TICKET_ABOVE_LIMIT = 2,
	IDENTIFIED_TICKET_BETWEEN_LIMITS = 3,

	NOT_IDENTIFIED = 100,
	NOT_IDENTIFIED_TICKET_ABOVE_LIMIT = 101,
	NOT_IDENTIFIED_TICKET_BETWEEN_LIMITS = 102,
}

/*******************************************************************************
 * Nutrition
 ******************************************************************************/

export enum BKNutritionUnitEnum {
	NONE = 0,
	GRAM = 1,
	KILOCALORIE = 2,
}

export enum BKNutritionHeaderTypeEnum {
	NONE = 0,
	PER_PORTION = 1,
	PER_100G = 2,
	PER_100ML = 3,
}

/*******************************************************************************
 * Coupon
 ******************************************************************************/

export enum BKCouponTypeEnum {
	NONE = 0,
	ICOUPON = 1,
	CRM_COLLECT_PHONE = 2,
	CRM_GENERATE_CODE = 3,
	COUNT_KING_SIZE = 4,
	PREPAID = 5,
	BURGER_MYSTERE = 6,
	DEMOCRATIC_BURGER = 7,
	LOCAL_MAILINGS = 8,
	KINGDOM_COUPON = 9,
	/**
	 * When modified, please reflect the change in ts\libs\backend-common\src\lib\enums\coupon-type.ts
	 * and ts\libs\backend-common\src\lib\dtos\big-data\coupon.dto.ts ("algo" property constraints)
	 **/
}

export enum BKCouponEntryOptionsEnum {
	MANUAL,
	SCAN,
	ALL,
}

/*******************************************************************************
 * Resto state
 ******************************************************************************/

export enum GlobalRestaurantStateEnum {
	UNKNOWN = 0,
	CONFIG = 1,
	INSTALL = 2,
	WHITE = 3,
	PRODUCTION = 4,
}

export enum LocalRestaurantStateEnum {
	STATE_FIRST_INIT = 0,
	STATE_CLOSE_DAY = 1,
	STATE_OPEN_DAY = 2,
	STATE_PRE_RAZ = 3,
	STATE_RAZ = 4,
	STATE_POST_RAZ = 5,
}

/*******************************************************************************
 * Enumeration used for filtering navigation menus
 ******************************************************************************/

export enum BKNavigationExtraFilterEnum {
	NONE = 0,
	ONLY_LARGE = 1,
	ONLY_MEDIUM = 2,
}

/*******************************************************************************
 * Enumeration containing the families of the media targets
 ******************************************************************************/

export enum BKMediaTargetFamilyEnum {
	NONE = -1,
	DMB = 0,
	POS = 100,
	KIOSK = 200,
	ORB = 300,
	COD = 400,
	XMB = 500,
	SMART_COD = 600,
}

export enum BKMediaActionTypeEnum {
	DEFAULT = 0,
	ADD_PRODUCT = 1,
	ADD_MENU = 2,
	ADD_GAME = 3,
	ADD_DISCOUNT = 4,
	YES = 100,
	NO = 101,
	CLOSE = 102,
}

/**
 * Alien order state as used in local datas.
 *
 * The global counterpart is BKWebOrdersStatus (@bk/bo-global-common-enum).
 */
export enum WeborderState {
	STATE_PENDING_PRODUCTION = 0,
	STATE_IN_KITCHEN = 1,
	STATE_READY_FOR_COLLECTION = 2,
	STATE_COLLECTED = 3,
	STATE_PENDING_PAY = 4,
	STATE_CANCELLED = -1,
	STATE_AVL_ERROR = -2,
}

/*******************************************************************************
 * BurgerMystere
 ******************************************************************************/

export enum BKBurgerMystereIsValidCouponResultEnum {
	OK = 0,
	WRONG_TOKEN = 1,
	CLIENT_ALREADY_LOGGED = 2,
	PARSE_ERROR = 3, // Unable to process the endpoint's response
	CLIENT_WRONG_PARAMETER = 4, // no client id or no fr number passed in
	WRONG_PARAMETER = 5, // no client id or no fr number passed in
	SERVER_INTERNAL_ERROR = 6,
	TIMEOUT_ERROR = 7,
	UNKNOWN_ERROR = 8,
	CONNECTION_ERROR = 9,
}

export enum BKBurgerMystereBurnCouponResultEnum {
	OK = 0,
	WRONG_TOKEN = 1, // 401
	CLIENT_WRONG_PARAMETER = 4, // no client id or no fr number passed in
	SERVER_INTERNAL_ERROR = 6,
	TIMEOUT_ERROR = 7,
	UNKNOWN_ERROR = 8, // default response, happens when we fail but not sure why
	CONNECTION_ERROR = 9,
}

/*******************************************************************************
 * Kingdom
 ******************************************************************************/
/**
 * Enumeration representing a result for the getting a kingdom client
 */
export enum BKKingdomClientResultEnum {
	OK = 0,
	WRONG_TOKEN = 1,
	CLIENT_ALREADY_LOGGED = 2,
	CONNECTION_ERROR = 3,
	WRONG_PARAMETER = 4,
	SERVER_INTERNAL_ERROR = 5,
	TIMEOUT_ERROR = 6,
	UNKNOWN_ERROR = 7,
	CLIENT_PAYING_ORDER_PENDING = 8,
}

/**
 * Enumeration representing a result for the validating of some orders
 */
export enum BKKingdomValidateOrdersResultEnum {
	OK = 0,
	WRONG_PARAMETER = 1,
	WRONG_TOKEN = 2,
	UNKNOWN_RESTAURANT = 3,
	UNKNOWN_CUSTOMER = 4,
	SERVER_INTERNAL_ERROR = 5,
	TIMEOUT_ERROR = 6,
	UNKNOWN_ERROR = 7,
}

/**
 * Order event key for Kingdom discount in order event
 */
export enum BKKingdomInOrderEventKey {
	DISCOUNT_UUID = 'd_uuid',
	DISCOUNT_ID = 'd_id',
	CLIENT_ID = 'client_id',
	PRIVILEGE_ID = 'privilege_id',
	POINTS_USED = 'points_used',
	COUPON_ID = 'coupon_id',
	LOGIN_METHOD = 'login_method',
	KINGDOM_CLIENT_ID = 'kingdom_client_id',
	KINGDOM_CLIENT_VIP = 'kingdom_client_vip',
	TGF_CODE = 'tgf_code',
}

/**
 * Order event key for Kingdom discount in order event
 */
export enum BKBurgerMystereInOrderEventKey {
	GAME_UUID = 'game_uuid',
}

/**
 * Kingdom log method enum
 */
export enum BKKingdomLoginMethodEnum {
	QR_SCAN = 'qr_scan',
	INPUT_CODE = 'input_code',
	AUTO_FROM_ORDER_EVENT = 'auto_order_event',
}

/**
 * Order event key for info order tablet in order event
 */
export enum BKInfoOrderTabletEventKey {
	IN_ROOM = 'in_room',
}

export enum BKTableAllocationType {
	NONE = 0,
	TABLE_NUMBER = 1,
	EASEL_NUMBER = 2,
}

export enum BKTableAllocationLocationSpace {
	NONE = 'none',
	INDOORS = 'indoors',
	TERRACE = 'terrace',
}

export enum BKConsolidationStateEnum {
	/**
	 * Transition state.
	 */
	CONSOLIDATION_INVALID = 0,
	/**
	 * Only the mdmorder object was received for this order.
	 */
	CONSOLIDATION_ORDER_ONLY = 1,

	/**
	 * Only the mdmpay object was stored for this order.
	 */
	CONSOLIDATION_PAY_ONLY = 2,

	/**
	 * Both mdmpay and mdmorder are stored.
	 */
	CONSOLIDATION_ORDER_AND_PAY = 3,

	/**
	 * Order is consolidated with csiTickets, consolidatedttc/consolidatedht prices have been updated.
	 */
	CONSOLIDATION_CONSOLIDATED = 4,
}

/**
 * Order submission states enum (error state) as used in IBKExchangedOrderSubmission's ack callback.
 */
export enum BKExchangedOrderSubmissionStateEnum {
	STATE_ACCEPTED = 1,
	STATE_UNREACHABLE = 0,
	STATE_INVALID_ORDER = -1,
	STATE_INVALID_MENU = -1,
	STATE_PRODUCT_UNAVAILABLE = -1,
	STATE_UNEXPECTED_ERROR = -255,
}

/*******************************************************************************************************
 * Resto features
 ******************************************************************************************************/

export enum BKRestoFeaturesEnum {
	PAY_MODE_FILTERING = 'PAY_MODE_FILTERING',
	DELIVEROO = 'DELIVEROO',
	UBER_EATS = 'UBER_EATS',
	JUST_EAT = 'JUST_EAT',
	TABLE_ALLOCATION = 'TABLE_ALLOCATION',
	WAIT_AT_THE_TABLE = 'WAIT_AT_THE_TABLE',
	LOCAL_PRICE_CHANGE = 'LOCAL_PRICE_CHANGE',
	LOCAL_PRICE_CHANGE_DELIVERY = 'LOCAL_PRICE_CHANGE_DELIVERY',
}

/**
 * CLICK AND COLLECT
 */

export enum BKBoWebOrderRejectionReason {
	INTERNAL_ERROR = -1,
	NONE = 0,
	PRODUCT_NOT_AVAILABLE = 1,
	MENU_NOT_AVAILABLE = 2,
	CAMPAIGN_PRODUCT_NOT_AVAILABLE = 3,
	CAMPAIGN_MENU_NOT_AVAILABLE = 4,
	PRODUCT_NOT_AVAILABLE_ON_CHANNEL = 6,
	PRODUCT_NOT_AVAILABLE_BECAUSE_INGREDIENT = 7,
	PRODUCT_NOT_ACTIVE = 8,
	MENU_NOT_ACTIVE = 9,
	SERVICE_NOT_AVAILABLE = 10,
	BAD_REQUEST = 11,
}

export enum BKEnableCCOrdersEcoceaResponse {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED',
	RESTAURANT_NOT_FOUND = 'RESTAURANT_NOT_FOUND',
}

export enum BKOrderRequestType {
	ORDER = 'ORDER',
	PAYORDER = 'PAYORDER',
	PRODORDER = 'PRODORDER',
}


export enum BKDeliveryBrandName {
	DELIVEROO = 'deliveroo',
	UBER_EATS = 'ubereats',
	JUST_EAT = 'justeat',
	DELIVERY_BK = 'deliverybk',
	ECOCEA = 'ecocea'
}

export const NewDeliveryBrandNames = {
	DELIVEROO: BKDeliveryBrandName["DELIVEROO"],
	UBER_EATS: BKDeliveryBrandName["UBER_EATS"],
	JUST_EAT: BKDeliveryBrandName["JUST_EAT"],
	DELIVERY_BK: BKDeliveryBrandName["DELIVERY_BK"],
	ZOT: 'zot',
	PLEASE: 'please',
	LYVEAT: 'lyveat'
}

export enum BKDeliverySandboxName {
	CC = 'click & collect',
	DELIVERY_BK = 'LIVRAISON BK',
	KT = 'KING TABLE'
}


// Value corresponding to values in .env files in bk-bo-global3
// export const enum BKMenuType {
export enum BKMenuType {
	STANDARD = 1,
	STUDENTS = 2,
	KING_JUNIOR = 3,
	KING_JUNIOR_PLUS = 4,
}

export const enum ProfitCenter {
	KIOSK = "KIOSK",
	POS = "COMPTOIR",
	DRIVE = "DRIVE",
	WALK = "WALK",
	CLICK_AND_COLLECT = "CLICK & COLLECT",
	DELIVERY = "HOME DELIVERY",
}

export enum DeviceStatus {
	OK = 'OK',
	ERROR = 'ERROR',
	PAPER_LOW = 'PAPER_LOW',
	OFFLINE = 'OFFLINE',
	UNKNOWN = 'unknown',
}

export enum PeripheralApplicationsStatus {
	OK = 'okBackgroundColor',
	ERROR = 'errorBackgroundColor',
	OFFLINE = 'offlineBackgroundColor',
	INFO = 'infoBackgroundColor',
}

export enum ProductIdEnum {
	EXTRA_FEE = 0
}

