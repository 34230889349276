import { Component, Inject } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { TODAYS_OVERVIEW_FACADE } from '../../../features/todays-overview/facade/todays-overview-facade.injection.token';
import { TodaysOverviewServiceProvider } from '../../../features/todays-overview/facade/todays-overview-facade.provider.interface';

@Component({
	selector: 'dk-kiosk-sos-tile',
	templateUrl: './kiosk-sos-tile.component.html',
	styleUrls: ['./kiosk-sos-tile.component.scss'],
})
export class KioskSosTileComponent {
	public viewData$ = combineLatest([this._todaysOverviewFacade.kioskSos$, this._translateService.selectTranslation('sosKioskTile')]).pipe(
		map(([sos, i18n]) => {
			return { sos, i18n };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(TODAYS_OVERVIEW_FACADE) private _todaysOverviewFacade: TodaysOverviewServiceProvider
	) {}
}
