import { Component, Inject } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ANALYZE_HISTORY_FACADE } from '../../../features/analyze-history/facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryServiceProvider } from '../../../features/analyze-history/facade/analyze-history-facade.provider.interface';

@Component({
	selector: 'dk-passed-kiosk-sos-tile',
	templateUrl: './passed-kiosk-sos-tile.component.html',
	styleUrls: ['./passed-kiosk-sos-tile.component.scss'],
})
export class PassedKioskSosTileComponent {
	public viewData$ = combineLatest([this._analyzeHistoryFacade.kioskSos$, this._translateService.selectTranslation('sosKioskTile')]).pipe(
		map(([sos, i18n]) => {
			return { sos, i18n };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(ANALYZE_HISTORY_FACADE) private _analyzeHistoryFacade: AnalyzeHistoryServiceProvider
	) {}
}
