import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import * as api from '@dki/api-client';
import { Restaurant } from '@dki/api-client';
import { TranslateService } from '@libs/shared/modules/i18n';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { RESTAURANT_SELECT_FACADE } from '../facade/restaurant-select-facade.injection.token';
import { RestaurantSelectFacade } from '../facade/restaurant-select-facade.service';

@Component({
	selector: 'dk-restaurant-select',
	templateUrl: './restaurant-select.component.html',
	styleUrls: ['./restaurant-select.component.scss'],
})
export class RestaurantSelectComponent implements OnInit, OnDestroy {
	restaurantList$ = this.restaurantService.restaurantsList$.pipe(map((list) => [...list].sort(this.sortfn)));

	// is enabled only for synthesis report for now
	multiSelectionEnabled = false;

	selectedRestaurant: Restaurant;
	multiSelectedRestaurants: Restaurant[] = [];
	oldSelection: Restaurant[] = [];

	destroy$: Subject<boolean> = new Subject<boolean>();
	searchQuery = '';

	i18n$ = this._translateService.selectTranslation('misc');

	@Output() restaurantSelected = new EventEmitter<Restaurant | Restaurant[]>();

	constructor(
		private _translateService: TranslateService,
		@Inject(RESTAURANT_SELECT_FACADE) protected readonly restaurantService: RestaurantSelectFacade,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.restaurantService.selectedRestaurant$.pipe(takeUntil(this.destroy$)).subscribe((restaurant) => {
			this.selectedRestaurant = restaurant;
			this.multiSelectedRestaurants.push(restaurant);
		});
		this.restaurantService.multiSelectionEnabled$.pipe(takeUntil(this.destroy$)).subscribe((enabler) => {
			this.multiSelectionEnabled = enabler;
			this.ref.detectChanges();
		});
		this.restaurantService.selectedRestaurants$.pipe(takeUntil(this.destroy$)).subscribe((list) => {
			if (list.length > 0) {
				this.multiSelectedRestaurants = [...list];
			}
		});
		//this.initFavoriteRestaurant();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	selectRestaurant(restaurantSelection: MatSelectChange | { value: api.Restaurant }) {
		this.restaurantService.persistRestaurant(restaurantSelection.value?.id, false, restaurantSelection.value);
		this.restaurantService.setRestaurant(restaurantSelection.value);
		this.restaurantSelected.emit(restaurantSelection.value);
	}

	multiSelectRestaurants(restaurantSelection: MatSelectChange | { value: api.Restaurant[] }) {
		if (restaurantSelection.value.length === 0) {
			this.multiSelectedRestaurants = [this.selectedRestaurant];
			this.restaurantService.persistRestaurant(
				this.multiSelectedRestaurants.map((res) => res?.id),
				true,
				this.multiSelectedRestaurants.map((res) => res)
			);
			this.restaurantService.setMultiRestaurants(this.multiSelectedRestaurants);
		} else {
			this.restaurantService.persistRestaurant(
				restaurantSelection.value.map((res) => res?.id),
				true,
				restaurantSelection.value.map((res) => res)
			);
			this.restaurantService.setMultiRestaurants(restaurantSelection.value);
			this.restaurantSelected.emit(restaurantSelection.value);
			this.oldSelection = [...this.multiSelectedRestaurants];
		}
	}

	initFavoriteRestaurant() {
		if (!this.restaurantService.browserStorageChecked) {
			const { favoriteRestaurant, favoriteRestaurants } = this.restaurantService.getPersistedRestaurant();
			this.restaurantService.restaurantsList$
				.pipe(
					filter((list) => list.length > 0),
					takeUntil(this.destroy$)
				)
				.subscribe((list) => {
					if (favoriteRestaurant) {
						const restaurant = list.filter((res) => res.id === favoriteRestaurant)[0];
						if (restaurant)
							this.selectRestaurant({
								value: restaurant,
							});
					}
					if (favoriteRestaurants) {
						const restaurants = list.filter((res) => {
							return favoriteRestaurants.includes(res.id);
						});
						if (restaurants && restaurants.length)
							this.multiSelectRestaurants({
								value: restaurants,
							});
					}
				});
			this.restaurantService.browserStorageChecked = true;
		}
	}

	isHidden(name: string) {
		return !name.toLowerCase().includes(this.searchQuery.toLowerCase());
	}

	sortfn(a, b) {
		return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
	}

	isSelected(restaurant) {
		return this.multiSelectedRestaurants.includes(restaurant);
	}

	deSelect(restaurant) {
		if (this.multiSelectedRestaurants.length === 1) return void 0;
		const index = this.multiSelectedRestaurants.indexOf(restaurant);
		if (index !== -1) {
			this.multiSelectedRestaurants = this.multiSelectedRestaurants.filter((e) => e !== restaurant);
			this.multiSelectRestaurants({ value: this.multiSelectedRestaurants });
			this.ref.detectChanges();
		}
	}
}
