export enum BrandName {
	BurgerKing_France = 'bkfr',
	BurgerKing_Reunion = 'bkre',

	HollysDiner = 'hdfr',
	Quick = 'qrfr',
	Cora_France = 'cofr',
	PokeMama_France = 'pmfr',
	CapMerchant_Reunion = 'cmre',
	BChef_France = 'bcfr',

	Default = 'Default',

	Mqsr = 'mqsr',
	/**
	 * Fallback for whitelabelled apps.
	 */
	Unknown = 'unknown',
}

export enum BrandNameLong {
	BurgerKing_France = 'BurgerKing_France',
	BurgerKing_Reunion = 'BurgerKing_La_Reunion',

	HollysDiner = 'Hollys_Dinner',
	Quick = 'Quick',
	Quick_old = 'Quick',
	Cora_France = 'Cora',
	PokeMama_France = 'Pokemama',
	CapMerchant_Reunion = 'CapMerchant',
	BChef_France = 'BeChef',

	Default = 'Default',

	Mqsr = 'mqsr',
	/**
	 * Fallback for whitelabelled apps.
	 */
	Unknown = 'unknown',
}
