import { Component, Input } from '@angular/core';
import { TranslateService } from '@libs/shared/modules/i18n';

@Component({
	selector: 'dk-horizontal-bars',
	templateUrl: './horizontal-bars.component.html',
	styleUrls: ['./horizontal-bars.component.scss'],
})
export class HorizontalBarsComponent {
	@Input() bars: { label: string; percentage: number }[] = [];
	tableRowLabels$ = this._translateService.selectTranslation('table-row-labels');

	constructor(private _translateService: TranslateService) {}

	barWidth(barPercentage: number): string {
		const percent = barPercentage.toFixed(2);
		return `calc(${percent}% - 24px`;
	}

	barPercent(barPercentage: number): string {
		return `${barPercentage}%`;
	}
}
